import * as React from 'react';
import { Logger } from 'aws-amplify';
import { useState} from "react";
import { Loader } from "@progress/kendo-react-indicators";

const logger = new Logger('NNLoader', 'INFO');


const NNLoader = () => {

  return (
    <h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Loader type='infinite-spinner'/></h1>    
  )
}

export default NNLoader;