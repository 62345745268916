import React from 'react';

export const UserContext = React.createContext({
    languageId: 'en',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    initials: '',
    country: '',
    team: '',
    role: '',
    onLanguageChange: () => {},
    onProfileChange: () => {},
    signOut: () => {},
    localeId: '',
    group: ''
});

UserContext.displayName = 'UserContext';