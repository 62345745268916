/*
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          teal: colors.teal,
          cyan: colors.cyan,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
*/

import lucid_illust_nn_front from "../assets/images/lucid_illust_nn_front.png";
import nn_logo from "../assets/images/neuralconnect-logo-black-xsmall-1.svg"

import {
  EmojiHappyIcon,
  LockClosedIcon,
  ChartBarIcon,
} from '@heroicons/react/outline'


const features = [
  {
    name: 'Simple',
    description: 'Solutions that are simple to use and navigate to support the diversity of your community of users.',
    icon: EmojiHappyIcon,
  },
  {
    name: 'Data Focused',
    description: 'Capturing data that is important to support the outcomes that your programs are looking to achieve.',
    icon: ChartBarIcon,
  },
  {
    name: 'Safe',
    description: 'Solutions that are secure by design. Meets HIPAA compliance standards and supports PI and PII confidentiality and privacy practice requirements.',
    icon: LockClosedIcon,
  },
]

const footerNavigation = {
  company: [
    { name: 'About', href: '/about' },
  ],

}

export default function MainLandingPrimarySection2() {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">

        <main>
          <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
            <div className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:py-24">
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <span className="block">Supporting our communities</span>
                      <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400 sm:pb-5">
                        through innovative technology
                      </span>
                    </h1>
                    <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                      A platform that connects the needs of people to the providers that can support them.
                    </p>

                  </div>
                </div>
                <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    <img
                      className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src={lucid_illust_nn_front}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>



          {/* Feature section with grid */}
          <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Access and Flexibility</h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Everything you need to support your community
              </p>
              <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                Software-as-a-service (SaaS) solutions that provides multi-platform clients (computer, phone, tablet) from a single application instance with no information cached or stored on any client - allowing a seamless and secure experience from any device.
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                              <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                          <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

        </main>

        <footer className="bg-gray-50" aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>

          <div className="flex flex-row gap-4 pt-12 sm:px-6 lg:pt-16 lg:px-8 flex-wrap">
            <div className="flex flex-col gap-2 grow items-center lg:items-start md:items-start">
              <div className="">
                <img
                    className="h-10"
                    src={nn_logo}
                    alt="Neural Network"
                  />                
              </div>
              <div className="text-center sm:text-center md:text-left lg:text-left">
                <p className="text-gray-500 text-base max-w-md">
                  Making our communities a better place through outcome based programs.
                </p>                
              </div>
            </div>
            <div className="grow mt-8 md:mt-0 lg:text-right md:text-right sm:text-center text-center lg:pr-10 md:pr-10">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                  <ul role="list" className="mt-2 space-y-4">
                    {footerNavigation.company.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 py-8">
            <p className="text-base text-gray-400 text-center">&copy; 2021 Neural Connect, Inc. All rights reserved.</p>
          </div>

        </footer>
      </div>
    </div>
  )
}
