import '@progress/kendo-theme-default/dist/all.css';
import { Logger, Hub, DataStore } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Amplify } from "aws-amplify";
import "./authentication/styles.css";

import DrawerRouterContainer from './components/DrawerRouterContainer';

import MainLanding from './pages/MainLanding';
import Contact from './pages/Contact';
import About from './pages/About'

import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

//Global Amplify logger setting
Amplify.Logger.LOG_LEVEL = 'DEBUG';
//Amplify.Logger.LOG_LEVEL = 'INFO';
//Amplify.Logger.LOG_LEVEL = 'WARN';

const logger = new Logger('Main', 'INFO');

export function Main() {

    const localeId = 'en-US';

    return (
    
      <div className="Main" style={{backgroundColor:'#ffffff'}}>
        <LocalizationProvider language={localeId}>
            <IntlProvider locale={localeId}>
                <BrowserRouter>
                  <DrawerRouterContainer>
                    <Routes>
                      <Route path="/" element={<MainLanding />} />
                      <Route path="contact" element={<Contact />} />
                      <Route path="about" element={<About />} />
                    </Routes>
                  </DrawerRouterContainer>
                </BrowserRouter>
            </IntlProvider>
        </LocalizationProvider>
      </div>

    );
}

export default Main;