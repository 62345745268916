import * as React from 'react';
import { Logger } from 'aws-amplify';

const logger = new Logger('ErrorBoundary', 'INFO');

/*
    TODO: Define different ErrorBoundary classes to have separate error pages for
    different areas
*/
class ErrorBoundary extends React.Component {
    state = { hasError: false };
  
    static getDerivedStateFromError(error: any) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      // You can also log the error to an error reporting service
      logger.debug("1.0 - componentDidCatch - error - " + error);
      logger.debug("1.0 - componentDidCatch - errorInfo - " + errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;