import { render } from "react-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

import Main from './Main';
import ErrorBoundary from './components/ErrorBoundary'

const rootElement = document.getElementById("root");
render(

    <ErrorBoundary>
    <Main />
    </ErrorBoundary>,
    rootElement
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
