import * as React from 'react';
import { Logger } from 'aws-amplify';
import MainLandingPrimarySection1 from './MainLandingPrimarySection1';

const logger = new Logger('MainLanding', 'INFO');

/*
    If we want to use React.FC which inherently has children props, otherwise add it in your 'MyProps' interface: children?: React.ReactNode
    const MainLanding: React.FC<MyProps> = (props) => {
*/
interface IMainLandingProps {
}

const MainLanding = ({ } : IMainLandingProps) => {

    return (
        
        <React.Fragment>
            <MainLandingPrimarySection1/>
        </React.Fragment>
        
    )
}

export default MainLanding;

