import * as React from 'react';
import ContactInfo1 from './ContactInfo1';

const Contact = () => {

    return (
        <React.Fragment>
            <ContactInfo1/>
        </React.Fragment>
    )
}

export default Contact;